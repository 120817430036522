/* global angular */

require('../../service/content.service.js')

angular.module('AemExtUpdatesTop')
  .component('fileImage', {
    template: require('./file-image.component.tpl.html'),
    controller: FileImageCtrl,
    controllerAs: 'vm',
    bindings: {
      file: '<'
    }
  })

FileImageCtrl.$inject = ['contentService']

function FileImageCtrl (contentService) {
  var vm = this

  vm.$onInit = function () {
    vm.file.blobUrl || contentService.getBlobUrl(vm.file.portal, vm.file.objectName).promise.then(function (res) {
      vm.file.blobUrl = res.data
    })
  }
}
