/* global angular */

require('./service/content.service.js')
require('./components/files/files.component.js')

angular.module('AemExtUpdatesTop')
  .controller('aemExtUpdatesTopCtrl', ctrl)

ctrl.$inject = ['adfsTokenService', 'contentService', 'appLoader']

function ctrl (adfsTokenService, contentService, appLoader) {
  var vm = this

  vm.$onInit = function () {
    vm.tokenReady = adfsTokenService.getToken()
    vm.errorBackend = null
    vm.files = []
    vm.getData = getData
    getData()
  }

  function getData (fromLocalDate, toLocalDate) {
    vm.errorBackend = null
    contentService.queryUpdatedFiles(fromLocalDate, toLocalDate).promise
      .then(function (res) {
        vm.files = res.data.metadata
      })
      .catch(function (err) {
        vm.errorBackend = err
      })
      .finally(function () {
        appLoader.hide()
      })
  }
}
