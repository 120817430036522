/* global angular */

angular.module('AemExtUpdatesTop')
  .factory('contentService', contentService)

contentService.$inject = ['$http', 'apiBase', '$q', 'adfsTokenService', 'wgService']

function contentService ($http, apiBase, $q, adfsTokenService, wgService) {
  var endpointPath = apiBase + 'v2/cms'
  var type = wgService.getParams().type || 'MSF'

  function endpoint (endPath) {
    if (endPath) {
      return endpointPath + '/' + endPath
    } else {
      return endpointPath
    }
  }

  function queryUpdatedFiles () {
    var canceller = $q.defer()

    var query = {
      conditionType: 'AND',
      subConditions: [
        {
          fieldName: 'rFolderPath',
          value: '/CS_IE/Systemy_a_aplikace/Extranet/' + type + '*',
          conditionType: 'LIKE'
        },
        {
          fieldName: 'keywordValue',
          value: 'Aktuality',
          conditionType: 'CONTAINS'
        }
      ]
    }

    var promise = $http({
      url: endpoint('contents/meta/search'),
      method: 'POST',
      timeout: canceller.promise,
      headers: {
        'Authorization': adfsTokenService.getToken()
      },
      data: query,
      params: {
        groups: ['INFO', 'TEASER', 'BASIC'],
        sort: 'aEffectiveDate.DESC',
        pageSize: 3
      }
    })

    return {
      promise: promise,
      canceller: canceller
    }
  }

  function getBlob (portal, objectName) {
    var canceller = $q.defer()

    var promise = $http({
      url: endpoint('contents/' + portal + '/' + objectName + '/content'),
      method: 'GET',
      responseType: 'blob',
      timeout: canceller.promise,
      headers: {
        'Authorization': adfsTokenService.getToken()
      }
    })

    return {
      promise: promise,
      canceller: canceller
    }
  }

  function getBlobUrl (portal, objectName) {
    var canceller = $q.defer()

    var promise = $http({
      url: endpoint('contents/' + portal + '/' + objectName + '/content'),
      method: 'GET',
      responseType: 'blob',
      timeout: canceller.promise,
      headers: {
        'Authorization': adfsTokenService.getToken()
      },
      transformResponse: appendTransform($http.defaults.transformResponse, toImageUrlTransformResponse)
    })

    return {
      promise: promise,
      canceller: canceller
    }
  }

  function appendTransform (defaults, transform) {
    defaults = angular.isArray(defaults) ? defaults : [defaults]
    return defaults.concat(transform)
  }

  function toImageUrlTransformResponse (blob, headerGetter) {
    var urlCreator = window.URL || window.webkitURL
    return urlCreator.createObjectURL(blob)
  }

  return {
    queryUpdatedFiles: queryUpdatedFiles,
    getBlob: getBlob,
    getBlobUrl: getBlobUrl
  }
}
