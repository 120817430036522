/* global angular */

angular.module('AemExtUpdatesTop')
  .component('fileAbstract', {
    template: require('./file-abstract.component.tpl.html'),
    controller: FileAbstractCtrl,
    controllerAs: 'vm',
    bindings: {
      file: '<'
    }
  })

FileAbstractCtrl.$inject = []

function FileAbstractCtrl () {
  var vm = this

  vm.$onInit = function () {
  }
}
