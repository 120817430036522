var inlet = require('aem-libs/src/initInlet.js').initInlet(
  'AemExtUpdatesTop',
  /* global WEBPACK_INLET_VERSION */ WEBPACK_INLET_VERSION
)
var angular = require('angular')
var adfsTokenService = require('aem-libs-angularjs/src/services/adfs-token.service.js')
require('aem-libs-angularjs/src/components/ext-backend-error/ext-backend-error.component.js')

angular.module('AemExtUpdatesTop', ['ExtBackendErrorCmp'])
  .factory('adfsTokenService', adfsTokenService)

require('./inlet.css')
require('./inlet.controller.js')

require('aem-libs-angularjs/src/bootstrap').bootstrap({
  element: inlet.element,
  appName: 'AemExtUpdatesTop',
  mainCtrlName: 'aemExtUpdatesTopCtrl',
  basePath: inlet.basePath,
  config: /* global WEBPACK_INLET_CONFIG */ WEBPACK_INLET_CONFIG,
  supportedLanguages: {
    cs: require('./i18n/cs.js').cs,
    en: require('./i18n/en.js').en
  },
  html: require('./inlet.html')
})
