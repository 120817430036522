/* global angular */

require('../file-common/file-common.component.js')
require('../file-image/file-image.component.js')
require('../file-abstract/file-abstract.component.js')
require('../file-wcm-link/file-wcm-link.component.js')

angular.module('AemExtUpdatesTop')
  .component('files', {
    template: require('./files.component.tpl.html'),
    controller: FilesCtrl,
    controllerAs: 'vm',
    bindings: {
      fileList: '<'
    }
  })

FilesCtrl.$inject = []

function FilesCtrl () {
  var vm = this

  vm.$onInit = function () {
    vm.isCommonFile = isCommonFile
    vm.isImage = isImage
    vm.isAbstract = isAbstract
    vm.isWcmLink = isWcmLink
  }

  function isCommonFile (file) {
    return !isImage(file) && !isAbstract(file) && !isWcmLink(file)
  }

  function isImage (file) {
    return file.rObjectType === 'cms_picture'
  }

  function isAbstract (file) {
    return file.alertFlag === true
  }

  function isWcmLink (file) {
    return Array.isArray(file.objectName.match(/xml$/gmi))
  }
}
