/* global angular */

require('../../service/content.service.js')
require('../../filters/human-file-size.filter.js')

angular.module('AemExtUpdatesTop')
  .component('fileCommon', {
    template: require('./file-common.component.tpl.html'),
    controller: FileCommonCtrl,
    controllerAs: 'vm',
    bindings: {
      file: '<'
    }
  })

FileCommonCtrl.$inject = ['adfsTokenService', 'appConfig']

function FileCommonCtrl (adfsTokenService, appConfig) {
  var vm = this

  vm.$onInit = function () {
    vm.downloadUrl = null
    vm.downloadUrl = appConfig.extP24.fileDownloadUrlPrefix + vm.file.objectName + '?link=' + adfsTokenService.getBase64Token()
  }
}
