/* global angular */

require('../../filters/human-file-size.filter.js')

angular.module('AemExtUpdatesTop')
  .component('fileWcmLink', {
    template: require('./file-wcm-link.component.tpl.html'),
    controller: FileWcmLinkCtrl,
    controllerAs: 'vm',
    bindings: {
      file: '<'
    }
  })

FileWcmLinkCtrl.$inject = ['wgService']

function FileWcmLinkCtrl (wgService) {
  var vm = this

  vm.$onInit = function () {
    var context = wgService.getParams().wcmHrefContext
    vm.wcmHtmlContentLink = context ? context + '?documentId=' + vm.file.objectName : vm.file.objectName
  }
}
